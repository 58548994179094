//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//var numeral = require("numeral");
//Vue.filter("formatNumber", function (value) {
//    return numeral(value).format("0,0"); 
//});

export default {
    name: 'QuarterlyKeyFigures',
    data: function () {
        return {
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: 2,
            },
            Filter: {},
            isChart: true,
            statementOfIncome: [],
            statementOfIncomeChart: null,
            originalSOICategories: [],
            filteredSOICategories: [],
            checkedSOICategories: [],
            originalSOISeries: [],
            filteredSOISeries: [],
            allSOICategoriesChecked: true,
            allSOISeriesChecked: true,

            balanceSheet: [],
            balanceSheetChart: null,
            originalBSCategories: [],
            filteredBSCategories: [],
            checkedBSCategories: [],
            originalBSSeries: [],
            filteredBSSeries: [],
            allBSCategoriesChecked: true,
            allBSSeriesChecked: true,

            cashFlow: [],
            cashFlowChart: null,
            originalCFCategories: [],
            filteredCFCategories: [],
            checkedCFCategories: [],
            originalCFSeries: [],
            filteredCFSeries: [],
            allCFCategoriesChecked: true,
            allCFSeriesChecked: true,

            tabName: ''

        };
    },
    methods: {
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        load: function () {
            let self = this;

            self.query.Filter = [];

            self.query.Filter.push({
                FieldName: "quarter",
                Operation: "!=",
                value: '5'
            });

            self.loadStatementOfIncomeData();
            self.loadBalanceSheetData();
            self.loadCashFlowData();
        },

        loadStatementOfIncomeData() {
            let self = this;
            self.ShowLoading();
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + 'IncomeList/GetAllForPublic', self.query)
                .then(response => {
                    if (response.data.IsSuccess) {
                        self.statementOfIncome = response.data.Data;

                        self.originalSOICategories = self.statementOfIncome.map((b) => { return { name: 'Q' + b.Quarter + '/' + b.Year?.toString().slice(2), visible: true }; })
                        self.checkedSOICategories = self.originalSOICategories.map((c) => { return c.name });
                        self.allSOICategoriesChecked = true;

                        var statementOfIncomeDetails = self.statementOfIncome.map((b) => b.IncomeListDetails).flat(1);
                        //var statementOfIncomeDetails = self.statementOfIncome.map((b) =>
                        //    b.IncomeListDetails.filter(d => d.TitleEn == 'Sales' || d.TitleEn == 'Sales Cost' || d.TitleEn == 'Total Income' || d.TitleEn == 'Other Revenues' || d.TitleEn == 'Total Revenues'))
                        //    .flat(1);
                        var statementOfIncomeDetailsCategories = [...new Set(statementOfIncomeDetails.map((d) => { return { TitleEn: d.TitleEn, TitleAr: d.TitleAr } }).map(JSON.stringify))].map(JSON.parse);

                        self.originalSOISeries = statementOfIncomeDetailsCategories.map(c => {
                            return {
                                name: c,
                                data: [...new Set(statementOfIncomeDetails)].filter(y => y.TitleEn == c.TitleEn).map(y => y.Value).map((y, i) => { return { category: self.originalSOICategories[i], y: y } }),
                                visible: true,
                                selected: true
                            }
                        });

                        document.getElementById("defaultOpen").click();
                    }
                    self.HideLoading();
                });
        },

        loadBalanceSheetData() {
            let self = this;
            self.ShowLoading();
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + 'BalanceSheet/GetAllForPublic', self.query)
                .then(response => {
                    if (response.data.IsSuccess) {
                        self.balanceSheet = response.data.Data;

                        self.originalBSCategories = self.balanceSheet.map((b) => { return { name: 'Q' + b.Quarter + '/' + b.Year?.toString().slice(2), visible: true }; })
                        self.checkedBSCategories = self.originalBSCategories.map((c) => { return c.name });
                        self.allBSCategoriesChecked = true;


                        var balanceSheetDetails = self.balanceSheet.map((b) => b.BalanceSheetDetails).flat(1);

                        //var balanceSheetDetails = self.balanceSheet.map((b) =>
                        //    b.BalanceSheetDetails.filter(d => d.TitleEn == 'Current Liabilities' || d.TitleEn == 'Non-Current Liabilities' || d.TitleEn == 'Shareholders Equity' || d.TitleEn == 'Other Liabilities' || d.TitleEn == 'Total Liabilities and Shareholder Equity'))
                        //    .flat(1);

                        var balanceSheetDetailsCategories = [...new Set(balanceSheetDetails.map((d) => { return { TitleEn: d.TitleEn, TitleAr: d.TitleAr } }).map(JSON.stringify))].map(JSON.parse);

                        self.originalBSSeries = balanceSheetDetailsCategories.map(c => {
                            return {
                                name: c,
                                data: [...new Set(balanceSheetDetails)].filter(y => y.TitleEn == c.TitleEn).map(y => y.Value).map((y, i) => { return { category: self.originalBSCategories[i], y: y } }),
                                type: 'column',
                                visible: true,
                                selected: true
                            }
                        });
                    }
                    self.HideLoading();
                });

        },

        loadCashFlowData() {
            let self = this;
            self.ShowLoading();
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + 'CashFlowStatement/GetAllForPublic', self.query)
                .then(response => {
                    if (response.data.IsSuccess) {
                        self.cashFlow = response.data.Data;

                        self.originalCFCategories = self.cashFlow.map((b) => { return { name: 'Q' + b.Quarter + '/' + b.Year?.toString().slice(2), visible: true }; })
                        self.checkedCFCategories = self.originalCFCategories.map((c) => { return c.name });
                        self.allCFCategoriesChecked = true;


                        var cashFlowDetails = self.cashFlow.map((b) => b.CashFlowStatementDetails)
                            .flat(1);

                        //var cashFlowDetails = self.cashFlow.map((b) =>
                        //    b.CashFlowStatementDetails.filter(d => d.TitleEn == 'Net Income' || d.TitleEn == 'Depreciation' || d.TitleEn == 'Accounts Receivable' || d.TitleEn == 'Inventory' || d.TitleEn == 'Prepaid Expenses'))
                        //    .flat(1);

                        var cashFlowDetailsCategories = [...new Set(cashFlowDetails.map((d) => { return { TitleEn: d.TitleEn, TitleAr: d.TitleAr } }).map(JSON.stringify))].map(JSON.parse);

                        self.originalCFSeries = cashFlowDetailsCategories.map(c => {
                            return {
                                name: c,
                                data: [...new Set(cashFlowDetails)].filter(y => y.TitleEn == c.TitleEn).map(y => y.Value).map((y, i) => { return { category: self.originalCFCategories[i], y: y } }),
                                type: 'column',
                                visible: true,
                                selected: true
                            }
                        });
                    }
                    self.HideLoading();
                });

        },

        moveDiv: function (isChart, tab) {
            var self = this;
            self.isChart = true;
            if (isChart) {
                
                self.ShowLoading();
                switch (tab) {
                    case "StatementOfIncome":
                        self.drawStatementOfIncomeChart();
                        break;
                    case "BalanceSheet":
                        self.drawBalanceSheetChart();
                        break;
                    case "CashFlow":
                        self.drawCashFlowChart();
                        break;
                    default:
                        self.drawStatementOfIncomeChart();
                }

            } else {
                self.isChart = false;
            }
            self.HideLoading();
        },

        openTab: function (evt, tabName) {
            var self = this;
            self.ShowLoading();
            self.tabName = tabName;
            // Declare all variables
            var i, tabcontent, tablinks;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
            evt.currentTarget.className += " active";


            switch (tabName) {
                case "StatementOfIncome":
                    self.drawStatementOfIncomeChart();
                    break;
                case "BalanceSheet":
                    self.drawBalanceSheetChart();
                    break;
                case "CashFlow":
                    self.drawCashFlowChart();
                    break;
                default:
                    self.drawStatementOfIncomeChart();
            }
            self.HideLoading();
        },

        //StatementOfIncome fns
        checkForAllSOICategory: function (event) {
            var self = this;
            self.originalSOICategories.forEach(function (category) {
                category.visible = event.target.checked;
            });

            if (!event.target.checked)
                self.checkedSOICategories = [];
            else
                self.checkedSOICategories = self.originalSOICategories.map((c) => { return c.name });

            self.drawStatementOfIncomeChart();
        },
        checkForAllSOISerie: function (event) {
            var self = this;
            self.originalSOISeries.forEach(function (serie) {
                serie.visible = event.target.checked;
                serie.selected = event.target.checked;
            });

            self.drawStatementOfIncomeChart();
        },

        checkSOICategory: function (event) {
            var self = this;
            self.originalSOICategories.forEach(function (category) {
                if (category.name == event.target.value) {
                    category.visible = event.target.checked;
                }

            });

            self.allSOICategoriesChecked = self.originalSOICategories.find(c => !c.visible) == null;

            self.drawStatementOfIncomeChart();
        },
        // functions that filter data and prepare it to be displayed
        filterSOICategories: function () {
            var self = this;
            self.filteredSOICategories = [];
            self.originalSOICategories.forEach(function (category) {
                if (category.visible) {
                    // push name only - chart.xAxis.categories requires array of String objects
                    self.filteredSOICategories.push(category.name);
                }
            });
            return self.filteredSOICategories;
        },

        filterSOISeries: function () {
            var self = this;
            self.filteredSOISeries = [];

            self.originalSOISeries.forEach(function (serie) {
                // serie options are merged during update,
                // so it's enough to initialize serie object only with data property
                var newSerie = {
                    data: [],
                    name: self.$i18n.locale == 'ar' ? serie.name.TitleAr : serie.name.TitleEn,
                    visible: serie.visible,
                    selected: serie.selected,
                };

                serie.data.forEach(function (point) {
                    if (point.category.visible) {
                        newSerie.data.push(point);
                    }
                });
                self.filteredSOISeries.push(newSerie);
            });

            return self.filteredSOISeries;
        },

        drawStatementOfIncomeChart: function () {
            var self = this;
            if (self.statementOfIncome.length > 0) {
                self.statementOfIncomeChart = Highcharts.chart('StatementOfIncomeContainer', {
                    rangeSelector: {
                        enabled: false
                    },
                    stockTools: {
                        gui: {
                            enabled: false // disable the built-in toolbar
                        }
                    },
                    chart: {
                        type: 'column',
                        events: {
                            redraw: function () { }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + this.y;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            //stacking: 'normal',
                            dataLabels: {
                                //enabled: true,
                            }
                        },
                        series: {
                            events: {
                                legendItemClick: function (event) {
                                    if (event.target.visible) {
                                        self.allSOISeriesChecked = false;
                                        this.checkbox.checked = false;
                                    }
                                    else {
                                        self.allSOISeriesChecked = self.originalSOISeries.find(c => !c.visible) == null;
                                        this.checkbox.checked = true;
                                    }
                                }
                                ,
                                checkboxClick: function (event) {
                                    if (event.checked) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }

                                    if (event.target.selected)
                                        self.allSOISeriesChecked = false;
                                    else
                                        self.allSOISeriesChecked = self.originalSOISeries.find(c => !c.selected) == null;
                                }
                            },
                            marker: {
                                enabled: true
                            },
                            allowPointSelect: true,
                            showCheckbox: true,

                        }
                    },
                    legend: {
                        //align: 'right',
                        //x: -30,
                        //verticalAlign: 'top',
                        //y: 25,
                        //floating: true,
                        //shadow: false
                    },
                    title: {
                        text: self.$t("StatementOfIncome")
                    },
                    xAxis: {
                        categories: self.filterSOICategories()
                    },
                    yAxis: {
                        title: {
                            text: self.$t("SR")
                        }
                    },
                    series: self.filterSOISeries()
                });
            }
        },


        //BalanceSheet fns
        checkForAllBSCategory: function (event) {
            var self = this;
            self.originalBSCategories.forEach(function (category) {
                category.visible = event.target.checked;
            });

            if (!event.target.checked)
                self.checkedBSCategories = [];
            else
                self.checkedBSCategories = self.originalBSCategories.map((c) => { return c.name });

            self.drawBalanceSheetChart();
        },
        checkForAllBSSerie: function (event) {
            var self = this;
            self.originalBSSeries.forEach(function (serie) {
                serie.visible = event.target.checked;
                serie.selected = event.target.checked;
            });

            self.drawBalanceSheetChart();
        },

        checkBSCategory: function (event) {
            var self = this;
            self.originalBSCategories.forEach(function (category) {
                if (category.name == event.target.value) {
                    category.visible = event.target.checked;
                }

            });

            self.allBSCategoriesChecked = self.originalBSCategories.find(c => !c.visible) == null;

            self.drawBalanceSheetChart();
        },
        // functions that filter data and prepare it to be displayed
        filterBSCategories: function () {
            var self = this;
            self.filteredBSCategories = [];
            self.originalBSCategories.forEach(function (category) {
                if (category.visible) {
                    // push name only - chart.xAxis.categories requires array of String objects
                    self.filteredBSCategories.push(category.name);
                }
            });
            return self.filteredBSCategories;
        },

        filterBSSeries: function () {
            var self = this;
            self.filteredBSSeries = [];

            self.originalBSSeries.forEach(function (serie) {
                // serie options are merged during update,
                // so it's enough to initialize serie object only with data property
                var newSerie = {
                    data: [],
                    name: self.$i18n.locale == 'ar' ? serie.name.TitleAr : serie.name.TitleEn,
                    type: serie.name.TitleEn == 'Total Liabilities and Shareholder Equity' ? 'spline' : serie.type,
                    visible: serie.visible,
                    selected: serie.selected,
                };

                serie.data.forEach(function (point) {
                    if (point.category.visible) {
                        newSerie.data.push(point);
                    }
                });
                self.filteredBSSeries.push(newSerie);
            });

            return self.filteredBSSeries;
        },

        drawBalanceSheetChart: function () {
            var self = this;

            if (self.balanceSheet.length > 0) {
                self.balanceSheetChart = Highcharts.chart('BalanceSheetContainer', {
                    rangeSelector: {
                        enabled: false
                    },
                    stockTools: {
                        gui: {
                            enabled: false // disable the built-in toolbar
                        }
                    },
                    chart: {
                        type: 'column',
                        events: {
                            redraw: function () { }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + this.y;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                //enabled: true,
                            }
                        },
                        series: {
                            events: {
                                legendItemClick: function (event) {
                                    if (event.target.visible) {
                                        self.allBSSeriesChecked = false;
                                        this.checkbox.checked = false;
                                    }
                                    else {
                                        self.allBSSeriesChecked = self.originalBSSeries.find(c => !c.visible) == null;
                                        this.checkbox.checked = true;
                                    }
                                }
                                ,
                                checkboxClick: function (event) {
                                    if (event.checked) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }

                                    if (event.target.selected)
                                        self.allBSSeriesChecked = false;
                                    else
                                        self.allBSSeriesChecked = self.originalBSSeries.find(c => !c.selected) == null;
                                }
                            },
                            marker: {
                                enabled: true
                            },
                            allowPointSelect: true,
                            showCheckbox: true,

                        }
                    },
                    legend: {
                        //align: 'right',
                        //x: -30,
                        //verticalAlign: 'top',
                        //y: 25,
                        //floating: true,
                        //shadow: false
                    },
                    title: {
                        text: self.$t("BalanceSheet")
                    },
                    xAxis: {
                        categories: self.filterBSCategories()
                    },
                    yAxis: {
                        title: {
                            text: self.$t("SR")
                        }
                    },
                    series: self.filterBSSeries()
                });
            }
        },

        //CashFlow fns
        checkForAllCFCategory: function (event) {
            var self = this;
            self.originalCFCategories.forEach(function (category) {
                category.visible = event.target.checked;
            });

            if (!event.target.checked)
                self.checkedCFCategories = [];
            else
                self.checkedCFCategories = self.originalCFCategories.map((c) => { return c.name });

            self.drawCashFlowChart();
        },
        checkForAllCFSerie: function (event) {
            var self = this;
            self.originalCFSeries.forEach(function (serie) {
                serie.visible = event.target.checked;
                serie.selected = event.target.checked;
            });

            self.drawCashFlowChart();
        },

        checkCFCategory: function (event) {
            var self = this;
            self.originalCFCategories.forEach(function (category) {
                if (category.name == event.target.value) {
                    category.visible = event.target.checked;
                }

            });

            self.allCFCategoriesChecked = self.originalCFCategories.find(c => !c.visible) == null;

            self.drawCashFlowChart();
        },
        // functions that filter data and prepare it to be displayed
        filterCFCategories: function () {
            var self = this;
            self.filteredCFCategories = [];
            self.originalCFCategories.forEach(function (category) {
                if (category.visible) {
                    // push name only - chart.xAxis.categories requires array of String objects
                    self.filteredCFCategories.push(category.name);
                }
            });
            return self.filteredCFCategories;
        },

        filterCFSeries: function () {
            var self = this;
            self.filteredCFSeries = [];

            self.originalCFSeries.forEach(function (serie) {
                // serie options are merged during update,
                // so it's enough to initialize serie object only with data property
                var newSerie = {
                    data: [],
                    name: self.$i18n.locale == 'ar' ? serie.name.TitleAr : serie.name.TitleEn,
                    type: serie.name.TitleEn == 'Cash at Begining of Period' || serie.name.TitleEn == 'Cash at End of Period' ? 'line' : serie.type,
                    visible: serie.visible,
                    selected: serie.selected,
                };

                serie.data.forEach(function (point) {
                    if (point.category.visible) {
                        newSerie.data.push(point);
                    }
                });
                self.filteredCFSeries.push(newSerie);
            });
            return self.filteredCFSeries;
        },

        drawCashFlowChart: function () {
            var self = this;
            if (self.cashFlow.length > 0) {
                self.cashFlowChart = Highcharts.chart('CashFlowContainer', {
                    rangeSelector: {
                        enabled: false
                    },
                    stockTools: {
                        gui: {
                            enabled: false // disable the built-in toolbar
                        }
                    },
                    chart: {
                        type: 'column',
                        events: {
                            redraw: function () { }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + this.y;
                        },
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                //enabled: true,
                            }
                        },
                        series: {
                            events: {
                                legendItemClick: function (event) {
                                    if (event.target.visible) {
                                        self.allCFSeriesChecked = false;
                                        this.checkbox.checked = false;
                                    }
                                    else {
                                        self.allCFSeriesChecked = self.originalCFSeries.find(c => !c.visible) == null;
                                        this.checkbox.checked = true;
                                    }
                                }
                                ,
                                checkboxClick: function (event) {
                                    if (event.checked) {
                                        this.show();
                                    } else {
                                        this.hide();
                                    }

                                    if (event.target.selected)
                                        self.allCFSeriesChecked = false;
                                    else
                                        self.allCFSeriesChecked = self.originalCFSeries.find(c => !c.selected) == null;
                                }
                            },
                            marker: {
                                enabled: true
                            },
                            allowPointSelect: true,
                            showCheckbox: true,

                        }
                    },
                    legend: {
                        //align: 'right',
                        //x: -30,
                        //verticalAlign: 'top',
                        //y: 25,
                        //floating: true,
                        //shadow: false
                    },
                    title: {
                        text: self.$t("CashFlow")
                    },
                    xAxis: {
                        categories: self.filterCFCategories()
                    },
                    yAxis: {
                        title: {
                            text: self.$t("SR")
                        }
                    },
                    series: self.filterCFSeries()
                });
            }
        },

        Scroll: function () {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },

        handleScroll: function (event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    updated() {
        initGallerySlider();
    },
    mounted() {
        let self = this;
        self.load();
    },
    watch: {
        '$i18n.locale'() {
            let self = this;
            switch (self.tabName) {
                case "StatementOfIncome":
                    self.drawStatementOfIncomeChart();
                    break;
                case "BalanceSheet":
                    self.drawBalanceSheetChart();
                    break;
                case "CashFlow":
                    self.drawCashFlowChart();
                    break;
                default:
                    self.drawStatementOfIncomeChart();
            }
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    filters: {
        formatNumber(value) {
            return `${value.toLocaleString()}`
        }
    }
}
